<template>
  <div class="company-notices">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="通知通告发布" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 消息标题 -->
        <ml-input
          prop="titleLike"
          placeholder="请输入消息标题"
          style="margin-bottom: 0; margin-right: 40px"
          label="消息标题"
          v-model="searchData.titleLike"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="添加通告"
          submitIcon="el-icon-plus"
          :showCancel="false"
          @click-submit="clickAdd"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="通告标题" prop="title" width="300px" />
        <el-table-column align="center" label="通告内容" prop="content" />
        <el-table-column align="center" label="推送时间" prop="createTime" width="180px" />
        <el-table-column align="center" label="操作" priop="recordId" width="200px">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteDialogRef"
      :content="deleteDialogData.content"
      @click-submit="submitDeleteDialog"
    />
    <!-- 添加修改提示框 -->
    <ml-dialog
      width="600px"
      ref="actionDialogRef"
      :title="actionDialogData.title"
      @click-submit="submitActionDialog"
    >
      <template #body>
        <ml-form
          style="width: 600px"
          labelWidth="100px"
          :model="actionDialogForm"
          :rules="actionDialogFormRules"
          ref="actionDialogFormRef"
        >
          <!-- 标题 -->
          <ml-input
            prop="title"
            placeholder="请输入通告标题"
            label="通告标题"
            v-model="actionDialogForm.title"
          />
          <ml-input
            prop="content"
            placeholder="请输入通告内容"
            label="通告内容"
            type="textarea"
            :rows="5"
            :maxlength="400"
            showWorLimit
            v-model="actionDialogForm.content"
          />
          <el-form-item label="推送对象">
            <el-radio-group v-model="actionDialogForm.userStatus">
              <el-radio label="0">全部阿米巴</el-radio>
              <el-radio label="1">指定阿米巴</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 所属项目 -->
          <ml-cascader
            v-if="actionDialogForm.userStatus === '1'"
            prop="companyVos"
            :props="{ multiple: true, label: 'companyName', value: 'companyId' }"
            label="阿米巴:"
            :options="unitOptions"
            placeholder="请选择阿米巴"
            v-model="actionDialogForm.companyVosId"
            v-model:node="actionDialogForm.companyVos"
            style="width: 100%"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { tabberHeight, searchParams, getTabberData, replacePerCent } from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'CompanyNotices',
  setup() {
    const { dispatch, commit } = useStore()

    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      titleLike: ''
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 单位名下拉列表
    const unitOptions = ref([])
    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.titleLike = replacePerCent(searchData.titleLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetCompanyNotices',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getLogTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 删除
    const deleteDialogRef = ref()
    const deleteDialogData = reactive({
      content: '确定删除该通告？'
    })
    const clickDelete = (index, row) => {
      deleteDialogData.recordId = row.recordId
      deleteDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteDialog = () => {
      const { recordId } = deleteDialogData
      dispatch('fetchDeleteCompanyNotice', recordId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
          deleteDialogRef.value.showDialog = false
        }
      })
    }
    // 添加修改
    const actionDialogFormRef = ref()
    const actionDialogForm = reactive({
      title: '',
      content: '',
      userStatus: '0',
      companyVosId: [],
      companyVos: []
    })
    const companyVosRule = () => {
      if (actionDialogForm.userStatus === '1' && actionDialogForm.companyVos.length <= 0) {
        return false
      } else {
        return true
      }
    }
    const actionDialogFormRules = {
      title: [{ required: true, message: '请输入通告标题 ', trigger: 'blur' }],
      content: [{ required: true, message: '请输入通告内容 ', trigger: 'blur' }],
      companyVos: [{ validator: companyVosRule, message: '请输入通告内容 ', trigger: 'blur' }]
    }
    const actionDialogRef = ref()
    const actionDialogData = reactive({
      title: ''
    })
    const clickAdd = () => {
      actionDialogData.title = '添加通告'
      actionDialogForm.title = ''
      actionDialogForm.content = ''
      actionDialogForm.userStatus = '0'
      actionDialogForm.companyVos = []
      actionDialogRef.value.showDialog = true
    }
    const clickUpdate = async (index, row) => {
      actionDialogData.title = '修改通告'
      const data = await dispatch('fetchGetCompanyNotice', row.recordId)
      if (data && data.code === 200) {
        Object.keys(actionDialogForm).map(item => {
          if (item === 'companyVos') {
            if (data.data[item]) {
              actionDialogForm[item] = data.data[item]
              actionDialogForm['companyVosId'] = data.data[item].map(item => item.companyId)
            } else {
              actionDialogForm[item] = []
              actionDialogForm['companyVosId'] = []
            }
          } else {
            actionDialogForm[item] = data.data[item]
          }
        })
      }
      deleteDialogData.recordId = row.recordId
      actionDialogRef.value.showDialog = true
    }
    // 确定添加修改
    const submitActionDialog = () => {
      actionDialogFormRef.value.CustomFormRef.validate()
        .then(() => {
          const actionDialogFormParams = searchParams(actionDialogForm, ['companyVos'], true)
          let paramsName = 'fetchAddCompanyNotice'
          if (actionDialogData.title === '修改通告') {
            paramsName = 'fetchUpdateCompanyNotice'
            actionDialogFormParams.recordId = deleteDialogData.recordId
          }
          if (actionDialogForm.userStatus === '1' && actionDialogForm.companyVos.length > 0) {
            actionDialogFormParams.companyVos = actionDialogForm.companyVos.map(item => {
              return {
                companyId: item.companyId,
                companyName: item.companyName
              }
            })
          }
          dispatch(paramsName, actionDialogFormParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
              actionDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      deleteDialogRef,
      deleteDialogData,
      clickDelete,
      submitDeleteDialog,
      actionDialogRef,
      actionDialogData,
      submitActionDialog,
      clickAdd,
      clickUpdate,
      actionDialogFormRef,
      actionDialogForm,
      actionDialogFormRules,
      unitOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.company-notices {
  @extend %params-global;
}
</style>
